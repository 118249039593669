import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import unitService from '@/services/Unit/UnitService';
import {UNIT_MARK_FAILED, UNIT_MARK_PASSED} from '@/constants/Unit';

const getDefaultState = () => {
    return {
        activeUnitUuid: null,
        unit          : {
            fetched        : false,
            data           : null,
            exercises      : [],
            currentExercise: null,
            hasFinished    : false,
            passMark       : 75, // % needed to pass
        },
    };
};

const defaultExerciseStructure = () => {
    return {
        id           : null,
        exercise_type: null,
        attempts     : 0,
        score        : null,
        content      : {},
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {any}
         */
        resetState: state => Object.assign(state, getDefaultState()),

        /**
         * Set the active unit uuid.
         *
         * @param {*} state
         * @param {string} uuid
         *
         * @returns {void}
         */
        setActiveUnitUuid(state, uuid) {
            Vue.set(state, 'activeUnitUuid', uuid);
        },

        /**
         * Update the lessons and units available.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {void}
         */
        setUnitData(state, payload) {
            Vue.set(state.unit, 'data', payload);
        },

        /**
         * Update the lessons and units available.
         *
         * @param {*} state
         * @param {*} items
         *
         * @returns {void}
         */
        setExercises(state, items) {
            Vue.set(state.unit, 'exercises', items);
        },

        /**
         * Update the current exercise.
         *
         * @param {*} state
         * @param {*} index
         *
         * @returns {void}
         */
        setCurrentExercise(state, index) {
            Vue.set(state.unit, 'currentExercise', index);
        },

        /**
         * Mark the current active exercise.
         *
         * @param {*} state
         * @param {boolean} passed
         *
         * @returns {void}
         */
        markExercise(state, passed) {
            let exercise   = Object.assign(
                defaultExerciseStructure(),
                state.unit.exercises[state.unit.currentExercise],
            );
            exercise.score = passed ? UNIT_MARK_PASSED : UNIT_MARK_FAILED;
            exercise.attempts++;

            Vue.set(state.unit.exercises, state.unit.currentExercise, exercise);
        },
    },
    actions   : {
        /**
         * Update the active unit uuid and reset the state.
         *
         * @param {*} commit
         * @param {string} unitUuid
         *
         * @returns {void}
         */
        updateActiveUnitUuid({commit}, unitUuid) {
            commit('resetState');
            commit('setActiveUnitUuid', unitUuid);
        },

        /**
         * Fetch the unit and its exercises.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} getters
         * @param {String} unitUuid
         *
         * @returns {Promise<any>}
         */
        fetchUnit({commit, state, getters}, unitUuid) {
            return new Promise((resolve, reject) => {
                let uuid = getters.getActiveUnitUuid;

                !uuid ? reject('No active unit uuid set!') : null;

                if (!(state.unit.fetched)) {
                    unitService.fetchUnit(uuid)
                        .then(data => {
                            let payload = cloneDeep(data);
                            commit('setExercises', payload.exercises);

                            delete payload.exercises;
                            commit('setUnitData', payload);

                            state.unit.fetched = true;

                            resolve();
                        })
                        .catch(error => {
                            state.unit.fetched = false;
                            reject(error);
                        });
                }
            });
        },

        /**
         * Mark the current exercise as pass or fail.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} dispatch
         * @param {*} getters
         * @param {*} passed
         *
         * @returns {Promise<any>}
         */
        markCurrentExercise({commit, state, dispatch, getters}, passed) {
            let hasPassed    = passed;
            let nextExercise = state.unit.currentExercise + 1;

            commit('markExercise', passed);
            commit('setCurrentExercise', state.unit.currentExercise + 1);
            commit('Exercise/resetState', null, {root: true});

            if (nextExercise === getters.getNumberOfExercises) {
                state.unit.hasFinished = true;
            } else {
                let exercise = state.unit.exercises[state.unit.currentExercise];

                dispatch('Exercise/updateExercise', exercise, {root: true});
            }
        },

        /**
         * Start the loaded unit by starting the first exercise.
         *
         * @param {*} commit
         * @param {*} dispatch
         * @param {*} state
         *
         * @returns {void}
         */
        startUnit({commit, dispatch, state}) {
            commit('setCurrentExercise', 0);

            let exercise = state.unit.exercises[state.unit.currentExercise];

            dispatch('Exercise/updateExercise', exercise, {root: true});
        },
    },
    getters   : {
        /**
         * Get the active unit uuid.
         *
         * @param {*} state
         *
         * @returns {string|null}
         */
        getActiveUnitUuid: state => state.activeUnitUuid,

        /**
         * Get the unit meta data.
         *
         * @param {*} state
         *
         * @returns {Array|null}
         */
        getUnitData: state => state.unit.fetched ? state.unit.data : null,

        /**
         * Get the total exercises in the loaded unit.
         *
         * @param {*} state
         *
         * @returns {Number|null}
         */
        getNumberOfExercises: state => state.unit.fetched ? state.unit.exercises.length : null,

        /**
         * Get the current active exercise.
         *
         * @param {*} state
         *
         * @returns {*|null}
         */
        getCurrentExercise: state => {
            return state.unit.fetched
                ? state.unit.exercises[state.unit.currentExercise]
                : null;
        },

        /**
         * Get the current unit percentage completion status.
         *
         * @param {*} state
         * @param {*} getters
         *
         * @returns {number}
         */
        getCurrentUnitProgressPercentage: (state, getters) => {
            return Math.min(state.unit.currentExercise / Math.max(
                getters.getNumberOfExercises || 0, 1,
            ) * 100, 100);
        },

        /**
         * Check if the unit has finished.
         *
         * @param {*} getters
         *
         * @returns {boolean}
         */
        unitHasFinished: getters => getters.unit.hasFinished,

        /**
         * Get the current exercise type.
         *
         * @param {*} state
         * @param {*} getters
         *
         * @returns {String}
         */
        getCurrentExerciseType: (state, getters) => {
            return null !== state.unit.currentExercise && getters.getCurrentExercise
                ? getters.getCurrentExercise.type_name
                : null;
        },

        /**
         * Calculate the current user's score as a % of correct / total possible answers.
         *
         * @param {*} state
         * @param {*} getters
         *
         * @return {number}
         */
        getScore: (state, getters) => {
            const correct = state.unit.exercises
                .reduce((prev, next) => prev + ((next.score || '') === 'passed' ? 1 : 0), 0);

            return Math.min(
                Math.round(correct / (getters.getNumberOfExercises - 1 || 1) * 100),
                100);
        },

        /**
         * Determine whether the user has passed this unit.
         *
         * @param {*} state
         * @param {*} getters
         *
         * @return {boolean}
         */
        hasUserPassed: (state, getters) => {
            return getters.getScore >= state.unit.passMark;
        },
    },
};
