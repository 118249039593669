<template>
    <div class="loadingCapsule loadingCapsule__container"
         :class="{'loadingCapsule__container--loaded': loaded}">
        <div class="loadingCapsule__spinnerContainer" v-if="!showContent">
            <svg
                class="loadingCapsule__spinner"
                :class="{ 'loadingCapsule__spinner--loaded': !showSpinner}"
                :style="spinnerSvgStyle"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                display="block">
                <defs/>
                <g transform="translate(20 50)">
                    <circle r="6" fill="#40798c" transform="scale(.02266)">
                        <animateTransform
                            attributeName="transform"
                            type="scale" begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(40 50)">
                    <circle r="6" fill="#40798c" transform="scale(.08243)">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(60 50)">
                    <circle r="6" fill="#40798c" transform="scale(.38237)">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(80 50)">
                    <circle r="6" fill="#40798c" transform="scale(.73165)">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </circle>
                </g>
            </svg>
        </div>
        <div class="loadingCapsule__content"
             :class="{'loadingCapsule__content--loaded': showContent}">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'LoadingCapsule',
        data    : function () {
            return {
                showSpinner: true,
                showContent: false,
            };
        },
        props   : {
            loaded          : {
                required: true,
                default : false,
                type    : Boolean,
            },
            spinnerMarginTop: {
                default: null,
                type   : [String, null],
            },
        },
        computed: {
            spinnerSvgStyle() {
                return this.spinnerMarginTop
                    ? `align-self: flex-start; margin-top: ${this.spinnerMarginTop}`
                    : '';
            },
        },
        watch   : {
            loaded: {
                immediate: true,
                handler  : function (value) {
                    if (true === value) {
                        this.showSpinner = false;
                        setTimeout(() => {
                            this.showContent = true;
                        }, 300);
                    } else {
                        this.showSpinner = true;
                        this.showContent = false;
                    }
                },
            },
        },
    };
</script>
