import axios from 'axios';
import store from '@/store/store';
import ApiNormalizer from 'json-api-normalize';

const fieldsToExtract = [
    'id',
    'name',
    'iso',
    'allow_as_interface',
    'allow_as_learning',
    'created_at',
    'updated_at',
    'courses.id',
    'courses.name',
    'courses.sort_order',
    'courses.created_at',
    'courses.updated_at',
    'courses.entity.id',
    'courses.entity.type_name',
    'courses.entity.local_text',
    'courses.entity.text',
    'courses.entity.audio',
    'courses.entity.video_url',
    'courses.entity.images',
    'courses.entity.created_at',
    'courses.entity.updated_at',
];

export default class fetchLanguages {
    /**
     * Handle fetching the languages.
     *
     * @returns {Promise<any>}
     */
    handle() {
        return new Promise((resolve, reject) => {
            const shouldFetchFromApiDirect = store.getters['Account/getOnlyFetchFromApi'];

            const url = shouldFetchFromApiDirect
                ? `${process.env.VUE_APP_API_URL}/api/languages?include=courses`
                : `${process.env.VUE_APP_CDN_URL}/static/languages.json`;

            axios.get(url)
                .then(response => {
                    resolve(ApiNormalizer(response.data).get(fieldsToExtract));
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
