<template>
    <div class="mainHero">
        <div class="filmGrain"/>
        <div class="mainHero__container px-4 sm:px-0 container mx-auto flex items-center justify-center">
            <div class="max-w-base z-0">
                <div class="text-center mx-auto flex justify-center">
                    <h1 class="mainHero__title no-margin hidden sm:block" v-text="$t('HOMEPAGE.MAIN_HERO.TITLE')"/>
                    <h2 class="mainHero__title no-margin block sm:hidden" v-text="$t('HOMEPAGE.MAIN_HERO.TITLE')"/>
                </div>
                <div class="text-center mx-auto">
                    <p class="mainHero__text" v-text="$t('HOMEPAGE.MAIN_HERO.COPY')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainHero',
    };
</script>
