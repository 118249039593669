<template>
    <div class="languageSelector">
        <div class="px-4 py-6">
            <loading-capsule :loaded="languagesFetched">
                <div class="container max-w-xs mx-auto flex flex-wrap justify-between">
                    <div v-for="language in learningLanguages"
                         @click="selectLanguage(language.value)"
                         :class="getAdditionalItemClass(language.iso)"
                         class="languageSelector__item">
                        <img class="languageSelector__flag"
                             :src="language.img"
                             :alt="language.title">
                        <p class="languageSelector__title" v-text="$t(`LANGUAGES.${language.iso}`)"/>
                    </div>
                </div>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';

    export default {
        name      : 'LearningLanguageSelector',
        methods   : {
            selectLanguage(iso) {
                this.$store.commit('Register/setLearningIso', iso);
            },
            getAdditionalItemClass(iso) {
                if (this.userHasSelected) {
                    return this.selectedIso === iso
                        ? 'languageSelector__item--selected'
                        : 'languageSelector__item--unselected';
                }
            },
        },
        computed  : {
            ...mapGetters({
                learningLanguages: 'Language/getLearningLanguages',
                selectedIso      : 'Register/getLearningIso',
            }),
            userHasSelected : function () {
                return null !== this.selectedIso;
            },
            userUuid        : function () {
                return this.$store.getters['Account/getUuid'];
            },
            languagesFetched: function () {
                return (this.learningLanguages || []).length > 0;
            },
        },
        components: {
            LoadingCapsule,
        },
    };
</script>
