export default {
    methods: {
        /**
         * Reset the main content stores.
         *
         * @return {void}
         */
        resetMainVuexContentStores() {
            this.$store.commit('Course/resetState');
            this.$store.commit('DailyProgress/resetState');
            this.$store.commit('Progress/resetState');
        },

        /**
         * Reset all main stores excluding the account store.
         *
         * @return {void}
         */
        resetAllVuexStores() {
            this.$store.commit('Course/resetState');
            this.$store.commit('DailyProgress/resetState');
            this.$store.commit('Exercise/resetState');
            this.$store.commit('Language/resetState');
            this.$store.commit('Progress/resetState');
            this.$store.commit('ProgressDispatcher/resetState');
            this.$store.commit('Register/resetState');
            this.$store.commit('Unit/resetState');
        },

        /**
         * Reset all main progress stores.
         *
         * @return {void}
         */
        resetUserProgressStores() {
            this.$store.commit('DailyProgress/resetState');
            this.$store.commit('Progress/resetState');
            this.$store.commit('ProgressDispatcher/resetState');
        }
    },
};
