import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import UnitModule from '@/store/modules/UnitModule';
import CourseModule from '@/store/modules/CourseModule';
import GlobalModule from '@/store/modules/GlobalModule';
import AccountModule from '@/store/modules/AccountModule';
import ProgressModule from '@/store/modules/ProgressModule';
import ExerciseModule from '@/store/modules/ExerciseModule';
import RegisterModule from '@/store/modules/RegisterModule';
import LanguagesModule from '@/store/modules/LanguagesModule';
import DailyProgressModule from '@/store/modules/DailyProgressModule';
import ProgressDispatcherModule from '@/store/modules/ProgressDispatcherModule';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: [
        'Account',
        'Global',
        'Register',
        'Progress',
        'DailyProgress',
    ],
});

export default new Vuex.Store({
    modules: {
        Account           : AccountModule,
        Course            : CourseModule,
        Exercise          : ExerciseModule,
        Global            : GlobalModule,
        Language          : LanguagesModule,
        DailyProgress     : DailyProgressModule,
        Progress          : ProgressModule,
        ProgressDispatcher: ProgressDispatcherModule,
        Register          : RegisterModule,
        Unit              : UnitModule,
    },
    plugins: [
        vuexLocal.plugin,
    ],
});
