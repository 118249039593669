<template/>
<script>
    import {mapGetters} from 'vuex';
    import debounce from 'lodash/debounce';
    import VuexUtils from '@/mixins/VuexUtils';
    import {CircuitBreaker} from '@/helpers/CircuitBreaker';

    /**
     * The debounce number. This is low and just to prevent both the watch and the
     * pollAndForceRefreshVuexStates from both calling the circuit fire method
     * simultaneously.
     *
     * @var {Number}
     */
    const FetchDebounceTimeoutMs = 25;

    /**
     * The number of ms to wait until a circuit breaker reattmepts fetching from the API after
     * the circuit trips and is OPEN.
     *
     * @type {number}
     */
    const CircuitFailureReattemptTimeout = 15000;

    /**
     * The settings object for all the circuit breakers.
     *
     * @type {Object}
     */
    const CircuitOptions = {
        failureThreshold: 2,
        successThreshold: 1,
        timeout         : CircuitFailureReattemptTimeout,
    };

    export default {
        name     : 'VuexStateWatcher',
        mixins   : [VuexUtils],
        data     : function () {
            return {
                vuexStatePollerInterval: null,
                fetchLanguagesCircuit  : null,
                fetchCourseCircuit     : null,
                userProgressCircuit    : null,
                dailyProgressCircuit   : null,
            };
        },
        computed : {
            ...mapGetters({
                isAuthenticated     : 'Account/appearsLoggedIn',
                courseFetched       : 'Course/courseHasFetched',
                userProgressFetched : 'Progress/userProgressHasFetched',
                dailyProgressFetched: 'DailyProgress/dailyProgressHasFetched',
                languagesFetched    : 'Language/languagesHaveFetched',
            }),
            shouldFetchLanguages    : function () {
                return false === this.languagesFetched;
            },
            shouldFetchCourse       : function () {
                return false === this.courseFetched && true === this.isAuthenticated;
            },
            shouldFetchUserProgress : function () {
                return false === this.userProgressFetched && true === this.isAuthenticated;
            },
            shouldFetchDailyProgress: function () {
                return false === this.dailyProgressFetched && true === this.isAuthenticated;
            },
        },
        created  : function () {
            if (null === this.vuexStatePollerInterval) {
                this.vuexStatePollerInterval = setInterval(this.pollAndForceRefreshVuexStates, 5000);
            }
        },
        destroyed: function () {
            clearInterval(this.vuexStatePollerInterval);
        },
        methods  : {
            /**
             * Look through states and force any fetches if required working with the
             * circuit breakers. This is required to force a re-attempt only in the instance
             * a fetch has failed and the circuit breaker is OPEN. If successful the circuit
             * should then be closed and the watch continue to work to fetch the states
             *
             * @return {void}
             */
            pollAndForceRefreshVuexStates: function () {
                if (this.shouldFetchLanguages) {
                    this.fetchLanguages();
                }

                if (this.shouldFetchCourse) {
                    this.fetchCourse();
                }

                if (this.shouldFetchUserProgress) {
                    this.fetchUserProgress();
                }

                if (this.shouldFetchDailyProgress) {
                    this.fetchDailyProgress();
                }
            },
            initLanguagesCircuit         : function () {
                if (!(this.fetchLanguagesCircuit instanceof CircuitBreaker)) {
                    this.fetchLanguagesCircuit = new CircuitBreaker(
                        this.$store.dispatch,
                        CircuitOptions,
                        'Languages circuit',
                    );
                }
            },
            fetchLanguages               : debounce(function () {
                this.fetchLanguagesCircuit.fire('Language/fetchLanguages', true)
                    .catch(error => console.warn(error));
            }, FetchDebounceTimeoutMs),
            initCourseCircuit            : function () {
                if (!(this.fetchCourseCircuit instanceof CircuitBreaker)) {
                    this.fetchCourseCircuit = new CircuitBreaker(
                        this.$store.dispatch,
                        CircuitOptions,
                        'Course circuit',
                    );
                }
            },
            fetchCourse                  : debounce(function () {
                this.fetchLanguagesCircuit.fire('Course/fetchCourse', true)
                    .catch(error => console.warn(error));
            }, FetchDebounceTimeoutMs),
            initUserProgressCircuit      : function () {
                if (!(this.userProgressCircuit instanceof CircuitBreaker)) {
                    this.userProgressCircuit = new CircuitBreaker(
                        this.$store.dispatch,
                        CircuitOptions,
                        'UserProgress circuit',
                    );
                }
            },
            fetchUserProgress            : debounce(function () {
                this.fetchLanguagesCircuit.fire('Progress/fetchUserProgressFromApi', true)
                    .catch(error => console.warn(error));
            }, FetchDebounceTimeoutMs),
            initDailyProgressCircuit     : function () {
                if (!(this.dailyProgressCircuit instanceof CircuitBreaker)) {
                    this.dailyProgressCircuit = new CircuitBreaker(
                        this.$store.dispatch,
                        CircuitOptions,
                        'DailyProgress circuit',
                    );
                }
            },
            fetchDailyProgress           : debounce(function () {
                this.fetchLanguagesCircuit.fire('DailyProgress/fetchDailyProgressFromApi', true)
                    .catch(error => console.warn(error));
            }, FetchDebounceTimeoutMs),
        },
        watch    : {
            isAuthenticated     : {
                immediate: false,
                handler  : function (isAuthenticated) {
                    switch (isAuthenticated) {
                        case true:
                            this.$store.commit('Register/resetState');
                            // nothing to do other watchers will action fetching the data
                            break;
                        case false:
                            this.resetAllVuexStores();
                    }
                },
            },
            languagesFetched    : {
                immediate: true,
                handler  : function () {
                    this.initLanguagesCircuit();

                    if (this.shouldFetchLanguages) {
                        this.fetchLanguages();
                    }
                },
            },
            courseFetched       : {
                immediate: true,
                handler  : function () {
                    this.initCourseCircuit();

                    if (this.shouldFetchCourse) {
                        this.fetchCourse();
                    }
                },
            },
            userProgressFetched : {
                immediate: true,
                handler  : function () {
                    this.initUserProgressCircuit();

                    if (this.shouldFetchUserProgress) {
                        this.fetchUserProgress();
                    }
                },
            },
            dailyProgressFetched: {
                immediate: true,
                handler  : function () {

                    this.initDailyProgressCircuit();
                    if (this.shouldFetchDailyProgress) {
                        this.fetchDailyProgress();
                    }
                },
            },
        },
    };
</script>
