import Vue from 'vue';
import {http} from '@/services/AxiosClient';
import {TYPE_ENTITY} from '@/constants/Progress';

const getDefaultState = () => {
    return {
        userProgress: {
            fetched: false,
            data   : [],
        },
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {void}
         */
        resetState: state => {
            Object.assign(state, getDefaultState());
        },

        updateUserProgressData: (state, payload) => Vue.set(state.userProgress, 'data', payload),
    },
    actions   : {
        /**
         * Fetch and update the user progress via the API.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} getters
         * @param {*} rootGetters
         * @param {Boolean} forceReload
         *
         * @returns {Promise<any>}
         */
        fetchUserProgressFromApi: ({commit, state, getters, rootGetters}, forceReload = false) => {
            return new Promise((resolve, reject) => {
                const uuid        = rootGetters['Account/getUuid'];
                const learningIso = rootGetters['Account/getLearningIso'];

                if (false === state.userProgress.fetched || true === forceReload) {
                    state.userProgress.fetched = true;

                    http.get(`/api/users/${uuid}/progress/${learningIso}`)
                        .then(response => {
                            commit('updateUserProgressData', response.data.data);

                            return resolve(response);
                        })
                        .catch(error => {
                            state.userProgress.fetched = false;

                            return reject(error);
                        });
                } else {
                    resolve(state.userProgress.data);
                }
            });
        },
    },
    getters   : {
        getUserProgress          : state => state.userProgress.data,
        userProgressHasFetched   : state => state.userProgress.fetched,
        getTotalWordsLearnedCount: state => {
            if (state.userProgress.data && state.userProgress.data.length) {
                return state.userProgress.data
                    .filter(item => item.type === TYPE_ENTITY)
                    .length;
            }

            return 0;
        },
        userHasPassedUnit        : state => uuid => {
            const unit = state.userProgress.data
                .find(progress => progress.id === uuid);

            return unit ? unit.times_correct > 0 : false;
        },
    },
};
