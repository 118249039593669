import ErrorResponseHandler from '@/helpers/ErrorResponseHandler';

const getDefaultState = () => {
    return {
        name            : null,
        email           : null,
        password        : null,
        optIn           : false,
        learningIso     : '',
        formErrorHandler: new ErrorResponseHandler(),
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {any}
         */
        resetState: state => Object.assign(state, getDefaultState()),

        setName       : (state, name) => state.name = name,
        setEmail      : (state, email) => state.email = email,
        setPassword   : (state, password) => state.password = password,
        setOptIn      : (state, optIn) => state.optIn = optIn,
        setLearningIso: (state, iso) => state.learningIso = iso,

    },
    actions   : {
        //
    },
    getters   : {
        getName            : state => state.name,
        getEmail           : state => state.email,
        getPassword        : state => state.password,
        getOptIn           : state => state.optIn,
        getLearningIso     : state => state.learningIso,
        getFormErrorHandler: state => state.formErrorHandler,
    },
};
