import store from '@/store/store';

export default function (to, from, next) {
    store.dispatch('Account/fetchUserFromApi', true)
        .then(() => next())
        .catch(error => {
            console.error(error);

            // Flush account data
            store.commit('Account/resetState');
            store.commit('DailyProgress/resetState');
            store.commit('Progress/resetState');
            store.commit('ProgressDispatcher/resetState');

            // Redirect back to homepage
            next({name: 'home'});
        });
}
