import Entity from '@/entities/Entity';
import cloneDeep from 'lodash.clonedeep';

export default class Exercise {
    /**
     * The exercise data.
     *
     * @type {*}
     */
    payload = {};

    /**
     * The Exercise constructor.
     *
     * @param {*} payload
     */
    constructor(payload) {
        this.payload = cloneDeep(payload);
    }

    /**
     * Get the exercise uuid.
     *
     * @return {String}
     */
    getId() {
        return this.payload.id;
    }

    /**
     * Get the exercise type.
     *
     * @return {String}
     */
    getType() {
        return this.payload.type_name;
    }

    /**
     * Get the single Entity.
     *
     * @return {Entity}
     */
    getSingleEntity() {
        return new Entity(this.payload.singleEntity);
    }

    /**
     * Get all the incorrect entities.
     *
     * @return {Array|null}
     */
    getIncorrectEntities() {
        return this.payload.incorrectEntities
            ? this.payload.incorrectEntities.map(entity => new Entity(entity))
            : null;
    }

    /**
     * Get all the correct entities.
     *
     * @return {null}
     */
    getCorrectEntities() {
        return this.payload.correctEntities
            ? this.payload.correctEntities.map(entity => new Entity(entity))
            : null;
    }

    /**
     * Get all other entities.
     *
     * @return {Array}
     */
    getAllOtherEntities() {
        return (this.getCorrectEntities() || [])
            .concat(this.getIncorrectEntities());
    }
}
