import 'whatwg-fetch';
import Vue from 'vue';
import i18n from '@/i18n';
import dayjs from 'dayjs';
import App from '@/App.vue';
import VueMeta from 'vue-meta'
import router from '@/router';
import '@/assets/css/app.scss';
import store from '@/store/store';
import VueScrollTo from 'vue-scrollto';
import {http} from '@/services/AxiosClient';

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(VueMeta);

Vue.prototype.$http = http;

// Setup day.js
var utc      = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
