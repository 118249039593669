<template>
    <div class="modal" :class="{'modal--full-screen' : enableFullScreen}">
        <div class="overlay" @click="handleClose"/>
        <div class="modal__container flex items-center justify-center">
            <div class="modal__modal container">
                <button
                    v-show="enableCloseButton"
                    class="modal__upperCloseBtn"
                    @click="handleClose">
                    <div>X</div>
                </button>
                <div class="modal__content">
                    <slot></slot>
                </div>
                <div class="modal__actions">
                    <slot name="actions-prefix"></slot>
                    <button class="button__btn button__btn--primary"
                            v-if="allowCancel"
                            @click="handleClose"
                            v-text="cancelButtonText"></button>
                    <slot name="actions-suffix"></slot>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name     : 'Modal',
        data     : function () {
            return {
                onEscape: e => {
                    if ((e.key === 'Escape') && this.allowEscape) {
                        this.handleClose();
                    }
                },
            };
        },
        props    : {
            allowCancel      : {
                default: true,
                type   : Boolean,
            },
            cancelButtonText : {
                default: 'Cancel',
                type   : String,
            },
            enableFullScreen : {
                default: false,
                type   : Boolean,
            },
            enableCloseButton: {
                default: true,
                type   : Boolean,
            },
            allowEscape      : {
                default: true,
                type   : Boolean,
            },
        },
        created  : function () {
            this.initOverlay();
        },
        destroyed: function () {
            this.teardownOverlay();
        },
        methods  : {
            handleClose    : function () {
                this.$emit('close');
            },
            initOverlay    : function () {
                document.addEventListener('keydown', this.onEscape);
                document.body.classList.add('app--overlayActive');
            },
            teardownOverlay: function () {
                document.removeEventListener('keydown', this.onEscape);
                document.body.classList.remove('app--overlayActive');
            },
        },
    };
</script>
