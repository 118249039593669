import FetchCourse from '@/services/Course/FetchCourse';

export default class CourseService {
    /**
     * Handle fetching the given course.
     *
     * @param {string} uuid
     *
     * @returns {Promise<any>}
     */
    static fetchCourse = uuid => (new FetchCourse()).handle(uuid);
}
