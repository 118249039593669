export const ISO_AR = {
    name     : 'Arabic',
    iso      : 'AR',
    localised: 'عربى',
    flag_icon: require('@/assets/images/flags/ar.svg?data'),
};

export const ISO_ZH = {
    name     : 'Chinese',
    iso      : 'ZH',
    localised: '中文',
    flag_icon: require('@/assets/images/flags/cn.svg?data'),
};

export const ISO_EN = {
    name     : 'English',
    iso      : 'EN',
    localised: 'English',
    flag_icon: require('@/assets/images/flags/gb.svg?data'),
};

export const ISO_FR = {
    name     : 'French',
    iso      : 'FR',
    localised: 'Français',
    flag_icon: require('@/assets/images/flags/fr.svg?data'),
};

export const ISO_DE = {
    name     : 'German',
    iso      : 'DE',
    localised: 'Deutsch',
    flag_icon: require('@/assets/images/flags/de.svg?data'),
};

export const ISO_IT = {
    name     : 'Italian',
    iso      : 'IT',
    localised: 'Italiano',
    flag_icon: require('@/assets/images/flags/it.svg?data'),
};

export const ISO_JA = {
    name     : 'Japanese',
    iso      : 'JA',
    localised: '日本人',
    flag_icon: require('@/assets/images/flags/jp.svg?data'),
};

export const ISO_PL = {
    name     : 'Polish',
    iso      : 'PL',
    localised: 'Polskie',
    flag_icon: require('@/assets/images/flags/pl.svg?data'),
};

export const ISO_PT = {
    name     : 'Portuguese',
    iso      : 'PT',
    localised: 'Português',
    flag_icon: require('@/assets/images/flags/pt.svg?data'),
};

export const ISO_RU = {
    name     : 'Russian',
    iso      : 'RU',
    localised: 'русский',
    flag_icon: require('@/assets/images/flags/ru.svg?data'),
};

export const ISO_ES = {
    name     : 'Spanish',
    iso      : 'ES',
    localised: 'Español',
    flag_icon: require('@/assets/images/flags/es.svg?data'),
};

export const ISO_TR = {
    name     : 'Turkish',
    iso      : 'TR',
    localised: 'Türk',
    flag_icon: require('@/assets/images/flags/tr.svg?data'),
};

export const ISO_ID = {
    name     : 'Indonesian',
    iso      : 'ID',
    localised: 'Bahasa Indonesia',
    flag_icon: require('@/assets/images/flags/id.svg?data'),
};

export const ISO_KO = {
    name     : 'Korean',
    iso      : 'KO',
    localised: '한국어',
    flag_icon: require('@/assets/images/flags/kr.svg?data'),
};

export const ISO_VI = {
    name     : 'Vietnamese',
    iso      : 'VI',
    localised: 'Tiếng Việt',
    flag_icon: require('@/assets/images/flags/vi.svg?data'),
};


export const LANGUAGES = [
    ISO_AR,
    ISO_ZH,
    ISO_EN,
    ISO_FR,
    ISO_DE,
    ISO_IT,
    ISO_JA,
    ISO_PL,
    ISO_PT,
    ISO_RU,
    ISO_ES,
    ISO_TR,
    ISO_ID,
    ISO_KO,
    ISO_VI,
];

export const INTERFACE_LANGUAGES = [
    ISO_AR,
    ISO_ZH,
    ISO_EN,
    ISO_FR,
    ISO_DE,
    ISO_IT,
    ISO_JA,
    ISO_PL,
    ISO_PT,
    ISO_RU,
    ISO_ES,
    ISO_TR,
    ISO_ID,
    ISO_KO,
    ISO_VI,
];
