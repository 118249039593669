import Vue from 'vue';
import dayjs from 'dayjs';
import {http} from '@/services/AxiosClient';

const getDefaultState = () => {
    return {
        dailyProgress: {
            fetched: false,
            data   : [],
        },
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {void}
         */
        resetState: state => {
            Object.assign(state, getDefaultState());
        },

        updateDailyProgressData: (state, payload) => Vue.set(state.dailyProgress, 'data', payload),
    },
    actions   : {
        /**
         * Fetch and update the user's daily progress via the API.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} getters
         * @param {*} rootGetters
         * @param {Boolean} forceReload
         *
         * @returns {Promise<any>}
         */
        fetchDailyProgressFromApi: ({commit, state, getters, rootGetters}, forceReload = false) => {
            return new Promise((resolve, reject) => {
                const uuid = rootGetters['Account/getUuid'];

                if (false === state.dailyProgress.fetched || true === forceReload) {
                    state.dailyProgress.fetched = true;

                    http.get(`/api/users/${uuid}/daily-progress`)
                        .then(response => {
                            commit('updateDailyProgressData', response.data.data);

                            return resolve(response);
                        })
                        .catch(error => {
                            state.dailyProgress.fetched = false;

                            return reject(error);
                        });
                } else {
                    resolve(state.dailyProgress.data);
                }
            });
        },
    },
    getters   : {
        dailyProgressHasFetched : state => state.dailyProgress.fetched,
        getDailyProgress        : state => state.dailyProgress.data,
        getDatesAsArray         : state => {
            return state.dailyProgress.data.map(item => new Date(item.date));
        },
        getLatestDate           : (state, getters) => {
            return dayjs(Math.max.apply(null, getters.getDatesAsArray));
        },
        getHasDailyProgressToday: (state, getters) => {
            return dayjs().tz('Europe/London').isSame(getters.getLatestDate, 'day');
        },
        getStreakLength         : (state, getters) => {
            const today      = dayjs().tz('Europe/London');
            const yesterday  = today.clone().subtract(1, 'day');
            const latestDate = getters.getLatestDate;
            const hasStreak  = today.isSame(latestDate, 'day')
                || yesterday.isSame(latestDate, 'day');

            // If user has no progress today or yesterday we cannot calculate a streak
            if (!hasStreak) return 0;

            let days         = 1;
            let dateIterator = dayjs(latestDate);
            let streakDayExists;

            // Dynamically calculate the number of consecutive days of progress from latest
            while (true) {
                dateIterator = dateIterator.subtract(1, 'days');

                streakDayExists = !!getters.getDatesAsArray
                    .find(date => dateIterator.isSame(date, 'day'));

                if (streakDayExists) {
                    days++;
                } else {
                    // We are at end of streak
                    break;
                }
            }

            return days;
        },
    },
};
