import VueScrollTo from 'vue-scrollto';

export default function (to, from, next) {
    if (document.documentElement.scrollTop > 0) {
        VueScrollTo.scrollTo('body', 500, {
            cancelable: false,
            onDone    : function (element) {
                next();
            },
        });
    } else {
        next();
    }
}
