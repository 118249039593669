<template>
    <div>
        <main-hero/>

        <div class="w-full h-14 bg-gray-900 text-white">
            <div class="container max-w-md mx-auto px-3 py-1 text-center">
                <p style="font-size:0.6rem" class="leading-tight">
                    {{ $t('HOMEPAGE.MAIN_HERO.DISCLAIMER') }}
                </p>
            </div>
        </div>

        <div class="HomeHero2">
            <div class="filmGrain"/>
            <div class="HomeHero2__container px-4 sm:px-0 container mx-auto flex items-center justify-center">
                <div class="max-w-base z-10">
                    <div class="text-center mx-auto px-1">
                        <p class="HomeHero2__text"
                           v-html="$t('HOMEPAGE.SECONDARY_HERO.COPY')"/>
                        <btn class="button__btn--secondary button__btn--fullWidth" v-scroll-to="`#languageSelector`">
                            {{ $t('CORE.GET_STARTED_CTA') }}
                        </btn>
                    </div>
                </div>
            </div>
        </div>

        <div class="languageSelectTitle pt-24 pb-1">
            <div class="container max-w-base mx-auto">
                <div class="mx-auto">
                    <p class="languageSelectTitle__title">
                        {{ $t('HOMEPAGE.LANGUAGE_SELECTION.TITLE') }}
                    </p>
                    <p class="languageSelectTitle__small">
                        {{ $t('HOMEPAGE.LANGUAGE_SELECTION.TITLE_SMALL_PRINT') }}
                    </p>
                </div>
            </div>
        </div>

        <learning-language-selector id="languageSelector"/>

        <!--You'll be learning...-->
        <div class="preRegisterSummary w-full">
            <div class="container max-w-base mx-auto px-3 py-12 text-center">
                <btn class="button__btn--secondary button__btn--fullWidth"
                     @click="redirectToRegistration">
                    {{ $t('CORE.GET_STARTED_CTA') }}
                </btn>
            </div>
        </div>
    </div>
</template>

<script>
    import Btn from '@/components/Btn';
    import MainHero from '@/components/partials/Home/MainHero';
    import LearningLanguageSelector from '@/components/partials/Home/LearningLanguageSelector';

    export default {
        name      : 'home',
        methods   : {
            redirectToRegistration: function () {
                this.$router.push({name: 'auth.register.step-2'});
            },
        },
        components: {
            MainHero,
            Btn,
            LearningLanguageSelector,
        },
    };
</script>
