<template>
    <div>
        <div class="fixed bottom-0 left-0">
            <button
                class="bg-gray-200 hover:bg-gray-400 shadow pointer p-2 pt-4 pr-4 rounded-tr-full"
                @click="showModal = true">
                <img class="w-4 h-4" src="~@/assets/images/icons/debug.svg?data" alt="debug-menu">
            </button>
        </div>
        <modal
            v-if="showModal"
            @close="showModal = false">
            <div class="text-center">
                <h3>Debug settings</h3>
                <hr>
            </div>

            <!--Quick helpers-->
            <div>
                <div class="w-full text-center my-2">
                    <button
                        class="mx-auto button__btn button__btn--primary"
                        @click="resetAllVuexStores">
                        Flush content
                    </button>
                </div>
            </div>

            <!--Vuex-->
            <div class="pt-2 input">
                <label class="input__label">
                    Vuex
                </label>
            </div>
            <div>
                <div class="input__checkbox pl-4">
                    <input
                        id="fetchFromApi"
                        type="checkbox"
                        name="fetchFromApi"
                        v-model="onlyFetchFromApi"
                        :value="true">
                    <label for="fetchFromApi">
                        Fetch all content directly from API instead of CDN
                    </label>
                </div>
                <div class="input">
                    <span class="input__label">
                        Content API endpoint:
                    </span>
                    <p class="text-xxs uppercase">{{ contentApiDomain }}</p>
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    import VuexUtils from '@/mixins/VuexUtils';
    import Modal from '@/components/structure/Modal';

    export default {
        name      : 'GlobalDebugModalContainer',
        mixins    : [VuexUtils],
        data      : function () {
            return {
                showModal: false,
            };
        },
        computed  : {
            onlyFetchFromApi: {
                get: function () {
                    return this.$store.getters['Account/getOnlyFetchFromApi'];
                },
                set: function (value) {
                    this.$store.commit('Account/updateOnlyFetchFromApi', value);
                    this.resetAllVuexStores();
                },
            },
            contentApiDomain: function () {
                return this.$store.getters['Account/getOnlyFetchFromApi']
                    ? `${process.env.VUE_APP_API_URL}`
                    : `${process.env.VUE_APP_CDN_URL}`;
            },
        },
        components: {
            Modal,
        },
    };
</script>
