import store from '@/store/store';

export default function (to, from, next) {
    const password       = (process.env.VUE_APP_PASSCODE || '');
    const storedPassword = store.getters['Global/getAppPasscode'];
    const toggleEnabled  = (process.env.VUE_APP_PASSCODE_ENABLED || '').toLowerCase() === 'true';

    // There's no password to check continue with app
    if (!toggleEnabled) {
        return next();
    }

    // A password is required and user has already entered the correct one
    if (password && password === storedPassword) {
        return next();
    }

    // we are already being redirected to security wall
    if (to.name === 'security-wall.index') {
        return next();
    }

    if (password) {
        return next({name: 'security-wall.index'});
    }

    next();
}
