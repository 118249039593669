import BasicFooter from '@/components/structure/BasicFooter';
import RedirectAuthenticated from '@/middleware/RedirectAuthenticatedUsersMiddleware';

const learnEnglishPageMap = {
    'de': 'englisch-lernen-online',
    'en': 'learn-english-online',
    'es': 'aprende-ingles-en-linea',
    'fr': 'apprendre-anglais-en-ligne',
    'it': 'imparare-inglese-in-linea',
    'ko': 'learn-english-online',
    'pt': 'aprender-ingles-conectadas',
    'zh': 'learn-english-online',
};

const learnFrenchPageMap = {
    'de': 'lerne-franzosisch-online',
    'en': 'learn-french-online',
    'es': 'aprende-frances-en-linea',
    'fr': 'apprendre-français-en-ligne',
    'it': 'imparare-francese-in-linea',
    'ko': 'learn-french-online',
    'pt': 'aprender-frances-conectadas',
    'zh': 'learn-french-online',
};

export default [
    // learnEnglishPages
    ...Object.entries(learnEnglishPageMap).map(([iso, pageSlug]) => {
        return {
            path       : `/${iso}/${pageSlug}`,
            name       : `${iso}.learn-english.index`,
            beforeEnter: RedirectAuthenticated,
            components : {
                header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                default: () => import(/* webpackChunkName: "learn-english-online" */ '@/views/static/LearnEnglishOnline'),
                footer : BasicFooter,
            },
            meta       : {
                iso: iso.toUpperCase(),
            },
        };
    }),

    // learnFrenchPages
    ...Object.entries(learnFrenchPageMap).map(([iso, pageSlug]) => {
        return {
            path       : `/${iso}/${pageSlug}`,
            name       : `${iso}.learn-french.index`,
            beforeEnter: RedirectAuthenticated,
            components : {
                header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                default: () => import(/* webpackChunkName: "learn-french-online" */ '@/views/static/LearnFrenchOnline'),
                footer : BasicFooter,
            },
            meta       : {
                iso: iso.toUpperCase(),
            },
        };
    }),
];
