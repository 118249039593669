import FetchLanguages from '@/services/Language/FetchLanguages';

export default class LessonService {
    /**
     * Handle fetching the languages.
     *
     * @returns {Promise<any>}
     */
    static fetchLanguages = () => (new FetchLanguages()).handle();
}
