import Vue from 'vue';
import Exercise from '@/entities/Exercise';
import {UNIT_TYPE_PHRASE, UNIT_TYPE_PHRASE_LOCALISED} from '@/constants/Unit';

const getDefaultState = () => {
    return {
        id           : null,
        content      : null,
        isCorrect    : null,
        canProceed   : false,
        type         : null,
        markRequested: false,
        wasSkipped   : false,
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {any}
         */
        resetState: state => Object.assign(state, getDefaultState()),

        /**
         * Set the exercise id.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setId: (state, payload) => Vue.set(state, 'id', payload),

        /**
         * Set the exercise content.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setExerciseContent: (state, payload) => Vue.set(state, 'content', payload),

        /**
         * Set the exercise correct state.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setIsCorrect: (state, payload) => Vue.set(state, 'isCorrect', payload),

        /**
         * Set the can proceed property.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setCanProceed: (state, payload) => Vue.set(state, 'canProceed', payload),

        /**
         * Set the exercise type.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setType: (state, payload) => Vue.set(state, 'type', payload),

        /**
         * Set the mark requested property.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setMarkRequested: (state, payload) => Vue.set(state, 'markRequested', payload),

        /**
         * Set the was skipped property.
         *
         * @param {*} state
         * @param {*} payload
         *
         * @returns {*}
         */
        setWasSkipped: (state, payload) => Vue.set(state, 'wasSkipped', payload),
    },
    actions   : {
        /**
         * Update the exercise with a new exercise.
         *
         * @param {*} commit
         * @param {*} payload
         *
         * @returns {Promise}
         */
        updateExercise({commit}, payload) {
            commit('setId', payload.id);
            commit('setExerciseContent', payload);
            commit('setType', payload.type_name);
        },
    },
    getters   : {
        /**
         * Get the exercise ID.
         *
         * @param {*} state
         *
         * @returns {String}
         */
        getId: state => state.id,

        /**
         * Get the exercise content.
         *
         * @param {*} state
         *
         * @returns {*}
         */
        getContent: state => state.content,

        /**
         * Get the Exercise entity.
         *
         * @param {*} state
         *
         * @return {Exercise}
         */
        getExerciseEntity: state => state.content ? new Exercise(state.content) : null,

        /**
         * Get the exercise type.
         *
         * @param {*} state
         *
         * @returns {String}
         */
        getType: state => state.type,

        /**
         * Get the exercise is correct property.
         *
         * @param {*} state
         *
         * @returns {Boolean}
         */
        isCorrect: state => {
            switch (state.type) {
                case UNIT_TYPE_PHRASE:
                case UNIT_TYPE_PHRASE_LOCALISED:
                    return true;
                default:
                    return state.isCorrect;
            }
        },

        /**
         * Get the can proceed property.
         *
         * @param {*} state
         *
         * @returns {Boolean}
         */
        canProceed: state => state.canProceed,

        /**
         * Get the mark requested property.
         *
         * @param {*} state
         *
         * @returns {Boolean}
         */
        markRequested: state => state.markRequested,

        /**
         * Get the was skipped property.
         *
         * @param {*} state
         *
         * @returns {Boolean}
         */
        wasSkipped: state => state.wasSkipped,
    },
};
