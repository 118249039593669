import {http} from '@/services/AxiosClient';

const getDefaultState = () => {
    return {
        batch: [
            //
        ],
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {void}
         */
        resetState: state => {
            Object.assign(state, getDefaultState());
        },

        addNewProgressRecord: (state, payload) => {
            state.batch.push(payload);
        },
    },
    actions   : {
        /**
         * Add the given user progress payload to the batch ready to be sent to the API.
         *
         * @param {*} commit
         * @param {*} payload
         *
         * @return {Promise<any>}
         */
        addProgressRecord: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                commit('addNewProgressRecord', payload);
                resolve(payload);
            });
        },

        sendToApi: ({commit, getters, rootGetters}) => {
            let userUuid = rootGetters['Account/getUuid'];

            return new Promise((resolve, reject) => {
                http
                    .post(`api/users/${userUuid}/progress`, {items: getters.getBatch})
                    .then(response => {
                        commit('resetState');
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
    ,
    getters   : {
        getUnpushedCount: state => state.batch.length,
        getBatch        : state => state.batch,
    },
};
