export const normaliseLatinStrsForComparison = string => {
    return (string || '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Normalize special latin characters
        .toLowerCase();
};

export const removeNonAlphaCharacters = string => {
    return (string || '')
        .replace(/[^\w\s]|_/g, '');
};
