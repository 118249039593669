<template>
    <div class="footer" v-show="visible">
        <div class="container mx-auto text-white md:flex h-full flex-wrap md:flex-nowrap px-4 md:px-0 ">
            <div class="w-full md:w-4/5 flex items-center">
                <ul class="footer__links">
                    <li>
                        <h5>
                            <router-link :to="{name:'home'}">{{ $t('CORE.HOME') }}</router-link>
                        </h5>
                    </li>
                    <li>
                        <h5>
                            <router-link :to="{name:'auth.register'}">{{ $t('CORE.SIGN_UP') }}</router-link>
                        </h5>
                    </li>
                    <li>
                        <h5>
                            <router-link :to="{name:'terms.index'}">Terms & Conditions</router-link>
                        </h5>
                    </li>
                    <li>
                        <h5>
                            <router-link :to="{name:'privacy.index'}">Privacy Policy</router-link>
                        </h5>
                    </li>
                    <li>
                        <h5>
                            <a href="https://blog.linquito.com/" target="_blank">Tech Blog</a>
                        </h5>
                    </li>
                </ul>
            </div>
            <div class="w-full md:w-1/5 flex items-center justify-end">
                <div class="block h-auto mr-2" style="padding-top: 1px;">
                    <p class="text-xxs no-margin">©{{ (new Date).getFullYear() }}</p>
                </div>
                <div class="block h-auto -mt-1">
                    <p class="font-secondary text-md text-white no-margin">Linquito</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name   : 'BasicFooter',
        data   : () => {
            return {
                visible: false,
            };
        },
        mounted: function () {
            setTimeout(() => {
                this.visible = true;
            }, 500);
        },
    };
</script>

<style scoped lang="scss">
    .footer {
        @apply h-auto py-4 bg-black;

        @screen md {
            @apply h-10 py-0;
        }

        a {
            &:hover {
                @apply text-white underline;
            }
        }

        &__links {
            li {
                @apply my-2;

                @screen md {
                    @apply my-0 inline-block px-2 relative border-r;

                    &:last-of-type {
                        @apply border-r-0;
                    }
                }
            }

            h5 {
                @apply my-0;
            }
        }
    }
</style>
