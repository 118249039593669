import {canUseWebP} from '@/helpers/BrowserHelper';
import {normaliseLatinStrsForComparison} from '@/helpers/StringHelpers';

export default class Entity {
    /**
     * The Entity data.
     *
     * @type {*}
     */
    payload = {};

    /**
     * The Entity constructor.
     *
     * @param {*} payload
     */
    constructor(payload) {
        this.payload = payload;
    }

    /**
     * Get the uuid.
     *
     * @return {String}
     */
    getId() {
        return this.payload.id;
    }

    /**
     * Get the native translation for this language.
     *
     * @return {String}
     */
    getLocalText() {
        return this.payload.local_text;
    }

    /**
     * Get the native translation for this entity without any special latin characters.
     *
     * @return {String}
     */
    getNormalisedLocalText() {
        return normaliseLatinStrsForComparison(this.getLocalText());
    }

    /**
     * Determine whether this entity has audio.
     *
     * @return {boolean}
     */
    hasAudio() {
        return !!this.payload.audio.urls.mp3;
    }

    /**
     * Return the MP3 src.
     *
     * @return {*}
     */
    getMp3Src() {
        return (this.payload.audio.uris || {}).mp3 || this.payload.audio.urls.mp3;
    }

    /**
     * Determine whether this entity has an image.
     *
     * @return {boolean}
     */
    hasImage() {
        return !!(this.payload.images && this.payload.images.urls.jpg);
    }

    /**
     * Return this entities image src.
     *
     * @return {*}
     */
    getImageSrc() {
        if (true === canUseWebP()) {
            return (this.payload.images.uris || {}).webp || this.payload.images.urls.webp;
        }

        return this.payload.images.urls.jpg;
    }

    /**
     * Get a translation for the given target language.
     *
     * @param {String} iso
     *
     * @return {String|null}
     */
    getTranslationFor(iso) {
        if (this.payload.text) {
            return this.payload.text[iso] || this.payload.text['EN'];
        } else {
            return null;
        }
    }
}
