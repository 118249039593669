import Axios from 'axios';
import store from '@/store/store';

export const http = Axios.create({
    withCredentials: true,
    baseURL        : process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
    request => {
        request.headers['Accept-Language'] = store.getters['Account/getInterfaceIso'] || 'en';

        const accessToken = store.getters['Account/getAccessToken'];

        // Inject the accessToken if is is present in the account store and insecure toggle is enabled
        // this should only be used in testing and staging environments and never on production!
        if (process.env.VUE_APP_ALLOW_INSECURE_ACCESS_TOKENS === 'true' && null !== accessToken) {
            request.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return request;
    },
);
