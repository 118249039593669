<template>
    <button
        :class="classes"
        class="button__btn"
        @click="$emit('click')">
        <slot/>
    </button>
</template>

<script>
    export default {
        name    : 'Btn',
        props   : {
            type      : {
                default: 'success',
                type   : String,
            },
            disabled  : {
                type   : Boolean,
                default: false,
            },
            fullWidth : {
                type   : Boolean,
                default: true,
            },
        },
        computed: {
            classes() {
                let addClasses = '';

                addClasses += this.disabled ? 'button__btn--disabled' : '';

                return addClasses;
            },
        },
    };
</script>
