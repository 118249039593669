import Entity from '@/entities/Entity';
import {LANGUAGES} from '@/resources/Countries';
import languageService from '@/services/Language/LanguageService';

const getDefaultState = () => {
    return {
        languages: {
            fetched: false,
            items  : null,
        },
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {any}
         */
        resetState: state => Object.assign(state, getDefaultState()),

        /**
         * Update the lessons and units available.
         *
         * @param {*}      state
         * @param languages
         *
         * @returns {void}
         */
        setLanguages(state, languages) {
            state.languages.items = languages;
        },
    },
    actions   : {
        /**
         * Fetch and update the lessons available.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} rootGetters
         * @param {Boolean} forceReload
         *
         * @returns {Promise<any>}
         */
        fetchLanguages({commit, state, rootGetters}, forceReload) {
            return new Promise((resolve, reject) => {
                if (!state.languages.fetched || forceReload === true) {
                    state.languages.fetched = true;

                    return languageService.fetchLanguages()
                        .then(items => {
                            const mappedItems = items.map(apiLanguage => {
                                // Find the local object for the language returned from the API
                                const localLanguage = LANGUAGES.find(lan => lan.iso === apiLanguage.iso);

                                // Merge the fields we need together
                                return {
                                    ...apiLanguage,
                                    title    : apiLanguage.name,
                                    value    : apiLanguage.iso,
                                    iso      : apiLanguage.iso,
                                    localised: localLanguage.localised || null,
                                    img      : localLanguage.flag_icon || null,
                                };
                            });

                            commit('setLanguages', mappedItems);
                            resolve();
                        })
                        .catch(error => {
                            state.languages.fetched = false;
                            reject(error);
                        });
                }

                resolve(state.languages.items);
            });
        },
    },
    getters   : {
        /**
         * Get all the languages.
         *
         * @param {*} state
         *
         * @returns {Array|null}
         */
        getLanguages: (state) => state.languages.fetched ? state.languages.items : null,

        /**
         * Have the languages fetched from the API.
         *
         * @param {*} state
         *
         * @return {boolean}
         */
        languagesHaveFetched: state => state.languages.fetched
            && state.languages.items instanceof Array,

        /**
         * Get the courses available for a specific ISO.
         *
         * @param {*} state
         *
         * @return {array|null}
         */
        getLanguageCourses: state => learningIso => {
            let courses = state.languages.items
                ? state.languages.items.find(lang => lang.iso === learningIso).courses
                : null;

            if (courses) {
                courses = courses.map(course => {
                    return {
                        ...course,
                        entity: new Entity(course.entity || null),
                    };
                });
            }

            return courses;
        },

        /**
         * Get the interface languages array.
         *
         * @return {array}
         */
        getInterfaceLanguages: state => {
            if (state.languages.items) {
                return state.languages.items
                    .filter(language => true === language.allow_as_interface);
            } else {
                return [];
            }
        },

        /**
         * Get the learning languages array.
         *
         * @return {array}
         */
        getLearningLanguages: state => {
            if (state.languages.items) {
                return state.languages.items
                    .filter(language => true === language.allow_as_learning);
            } else {
                return [];
            }
        },
    },
};
