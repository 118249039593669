import get from 'lodash.get';

export default class ErrorResponseHandler {
    /**
     * The response.
     *
     * @type {Object}
     */
    response = {};

    /**
     * Set the given response.
     *
     * @param data
     *
     * @returns {ErrorResponseHandler}
     */
    setResponse (data) {
        this.response = data;

        return this;
    }

    /**
     * Determine whether the error response contains the given key.
     *
     * @param {string} field
     *
     * @returns {boolean}
     */
    has (field) {
        return get(this.response.errors, field, null) !== null;
    }

    /**
     * Get the error message for the given field.
     *
     * @param {string} field
     *
     * @returns {*|String}
     */
    get (field) {
        let value = get(this.response.errors, field, null);

        if (value instanceof Array) {
            return value.join(', ');
        }

        return value;
    }

    /**
     * Reset the error handler.
     *
     * @returns {void}
     */
    reset () {
        this.response = {};
    }

    /**
     * Get all the errors formatted in an object.
     *
     * @returns {*|null}
     */
    getAllErrors () {
        if (this.response && this.response.errors && Object.keys(this.response.errors).length > 0) {
            let keys        = Object.keys(this.response.errors);
            let errorObject = {};

            keys.map((key) => {
                errorObject[key.replace('_', ' ')] = this.get(key);
            });

            return errorObject;
        }

        return null;
    }

    /**
     * Returns error message or empty string if error message is not set.
     *
     * @returns {String}
     */
    getErrorMessage () {
        return this.hasErrorMessage()
            ? this.response.error
            : '';
    }

    /**
     * Checks if response contains error message.
     *
     * @returns {Boolean}
     */
    hasErrorMessage () {
        return typeof this.response.error === 'string' && this.response.error.length > 0;
    }
}
