<template>
    <div id="app">
        <transition
            name="fade"
            mode="out-in">
            <router-view name="header"/>
        </transition>
        <transition
            name="fade"
            mode="out-in">
            <router-view/>
        </transition>
        <transition
            name="fade"
            mode="out-in">
            <router-view name="footer"/>
        </transition>
        <vuex-state-watcher :key="accountUuid"/>
        <global-debug-modal-container
            v-if="shouldShowDebugBar"/>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import VuexStateWatcher from '@/views/VuexStateWatcher';
    import GlobalDebugModalContainer from '@/components/debug/GlobalDebugModalContainer';

    export default {
        metaInfo() {
            return {
                title: this.$t('META.TITLE'),

                meta: [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: process.env.VUE_APP_ENV !== 'production'
                            ? 'noindex,nofollow'
                            : 'NOODP,NOYDIR,INDEX,FOLLOW',
                    },
                    {
                        vmid   : 'description',
                        name   : 'description',
                        content: this.$t('META.DESCRIPTION'),
                    },
                    {
                        name   : 'keywords',
                        content: this.$t('META.KEYWORDS'),
                    },

                    // Prevent Google auto translating the stie
                    {
                        name   : 'google',
                        content: 'notranslate',
                    },

                    // Allows the site to be used as an 'app' on ios and android
                    {
                        name   : 'apple-mobile-web-app-capable',
                        content: 'yes',
                    },
                    {
                        name   : 'mobile-web-app-capable',
                        content: 'yes',
                    },
                    {
                        name   : 'apple-mobile-web-app-title',
                        content: 'Linquito',
                    },

                    // OG SEO content see: https://ogp.me/
                    {
                        name   : 'og:title',
                        content: 'Linquito',
                    },
                    {
                        name   : 'og:type',
                        content: 'website',
                    },
                    {
                        name   : 'og:description',
                        content: this.$t('META.DESCRIPTION'),
                    },
                    {
                        name   : 'og:title',
                        content: this.$t('META.TITLE'),
                    },
                ],

                link: [
                    {
                        name    : 'alternate',
                        hreflang: 'en',
                        content : 'https://www.linquito.com',
                    },
                    // Should do all other languages here dynamically!
                ],
            };
        },
        data() {
            return {
                prevHeightMain  : 0,
                prevHeightHeader: 0,
            };
        },
        computed  : {
            ...mapGetters({
                interfaceIso: 'Account/getInterfaceIso',
                accountUuid : 'Account/getUuid',
            }),
            shouldShowDebugBar: function () {
                return 'true' === process.env.VUE_APP_DEBUG_BAR;
            },
        },
        watch     : {
            interfaceIso: {
                immediate: true,
                handler  : function (iso) {
                    this.$i18n.locale = iso.toLowerCase();

                    // Change the lang att on main html
                    document.getElementsByTagName('html')[0].setAttribute('lang', iso.toLowerCase());
                },
            },
        },
        components: {
            VuexStateWatcher,
            GlobalDebugModalContainer,
        },
    };
</script>
<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .4s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
