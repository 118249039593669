const getDefaultState = () => {
    return {
        passcode: null,
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {void}
         */
        resetState    : state => {
            Object.assign(state, getDefaultState());
        },
        setAppPasscode: (state, password) => {
            state.passcode = password;
        },
    },
    actions   : {},
    getters   : {
        getAppPasscode: state => state.passcode,
    },
};
