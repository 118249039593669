import axios from 'axios';
import store from '@/store/store';
import {http} from '@/services/AxiosClient';
import ApiNormalizer from 'json-api-normalize';

const fieldsToExtract = [
    'id',
    'name',
    'entity.id',
    'entity.type_name',
    'entity.local_text',
    'entity.text',
    'entity.audio',
    'entity.video_url',
    'entity.images',
    'entity.created_at',
    'entity.updated_at',
    'created_at',
    'updated_at',
    'exercises.id',
    'exercises.type_name',
    'exercises.sort_order',
    'exercises.created_at',
    'exercises.updated_at',
    'exercises.singleEntity.id',
    'exercises.singleEntity.type_name',
    'exercises.singleEntity.local_text',
    'exercises.singleEntity.text',
    'exercises.singleEntity.audio',
    'exercises.singleEntity.video_url',
    'exercises.singleEntity.images',
    'exercises.singleEntity.created_at',
    'exercises.singleEntity.updated_at',
    'exercises.correctEntities.id',
    'exercises.correctEntities.type_name',
    'exercises.correctEntities.local_text',
    'exercises.correctEntities.text',
    'exercises.correctEntities.audio',
    'exercises.correctEntities.video_url',
    'exercises.correctEntities.images',
    'exercises.correctEntities.created_at',
    'exercises.correctEntities.updated_at',
    'exercises.incorrectEntities.id',
    'exercises.incorrectEntities.type_name',
    'exercises.incorrectEntities.local_text',
    'exercises.incorrectEntities.text',
    'exercises.incorrectEntities.audio',
    'exercises.incorrectEntities.video_url',
    'exercises.incorrectEntities.images',
    'exercises.incorrectEntities.created_at',
    'exercises.incorrectEntities.updated_at',
];

export default class FetchUnit {
    /**
     * Handle fetching the unit and its exercises.
     *
     * @param {string} uuid
     *
     * @returns {Promise<any>}
     */
    handle(uuid) {
        return new Promise((resolve, reject) => {
            // Get cloud URL @todo determine whether browser support webp and load bundle else traditional
            const browserSupportsWebp      = true;
            const shouldFetchFromApiDirect = store.getters['Account/getOnlyFetchFromApi'];

            // Try from cloud first
            const cloudUrl    = browserSupportsWebp
                ? `${process.env.VUE_APP_CDN_URL}/static/units/${uuid}.bundle.json`
                : `${process.env.VUE_APP_CDN_URL}/static/units/${uuid}.json`;
            const fallbackUrl = `${process.env.VUE_APP_API_URL}/api/units/${uuid}`;

            const url    = shouldFetchFromApiDirect
                ? fallbackUrl
                : cloudUrl;
            const client = shouldFetchFromApiDirect ? http : axios;

            client.get(url)
                .then(response => {
                    resolve(ApiNormalizer(response.data).get(fieldsToExtract));
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
