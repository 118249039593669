import store from '@/store/store';

export default function (to, from, next) {
    if (!userHasValidCourse(store)) {
        next({name: 'app.courses'});
    } else {
        next();
    }
}

/**
 * Determine whether the customer's active course is set and matches the learning iso that
 * they have set on their profile.
 *
 * @param {*} store
 *
 * @returns {boolean}
 */
function userHasValidCourse(store) {
    const courseUuid        = store.getters['Account/getActiveCourseUuid'];
    const learningIso       = store.getters['Account/getLearningIso'];
    const courseLearningIso = store.getters['Course/getCourseLanguageIso'];

    // No course uuid set
    if (!courseUuid) {
        console.error('No course uuid is set yet!');

        return false;
    }

    // Current course iso doesn't match that of user's learning iso
    if (courseLearningIso && (courseLearningIso !== learningIso)) {
        console.error(`the user\'s current course iso[${courseLearningIso}] doesn\'t match their profile learning iso [${learningIso}]`);

        return false;
    }

    return true;
}
