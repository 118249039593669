import {http} from '@/services/AxiosClient';

const getDefaultState = () => {
    return {
        user: {
            fetched: false,
            uuid   : null,
            data   : {
                uuid              : null,
                name              : null,
                email             : null,
                opt_in            : null,
                interface_language: 'EN',
                learning_language : null,
                active_course_uuid: null,
                active_lesson_uuid: null,
                is_admin          : false,
                created_at        : null,
                updated_at        : null,
            },
            local  : {
                access_token    : null,
                onlyFetchFromApi: 'true' === (process.env.VUE_APP_FORCE_API_FETCH || 'false'),
            },
        },
    };
};
export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {void}
         */
        resetState: state => {
            // Preserve ISO
            const iso = state.user && state.user.data.interface_language
                ? state.user.data.interface_language
                : 'EN';

            // Reset state
            Object.assign(state, getDefaultState());

            // Re-add ISO back
            state.user.data.interface_language = iso;
        },

        updateUuid: (state, uuid) => {
            state.user.uuid = uuid;
        },

        updateUserData: (state, payload) => {
            state.user.data = payload;
        },

        updateUserHasFetched: (state, payload) => {
            state.user.fetched = payload;
        },

        updateInterfaceIso: (state, iso) => {
            state.user.data.interface_language = iso;
        },

        updateOnlyFetchFromApi: (state, payload) => {
            state.user.local.onlyFetchFromApi = payload;
        },

        updateActiveLessonUuid: (state, uuid) => {
            state.user.data.active_lesson_uuid = uuid;
        },

        updateAccessToken: (state, token) => {
            state.user.local.access_token = token;
        },
    },
    actions   : {
        /**
         * Manually update the user data state.
         *
         * @param {*} commit
         * @param {*} data
         *
         * @returns {Promise<any>}
         */
        setUserData: ({commit}, data) => {
            return new Promise((resolve, reject) => {
                commit('updateUserData', data);
                resolve(data);
            });
        },

        /**
         * Fetch and update the user profile via the API.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} getters
         * @param {Boolean} forceReload
         *
         * @returns {Promise<any>}
         */
        fetchUserFromApi: ({commit, state, getters}, forceReload = false) => {
            return new Promise((resolve, reject) => {
                const uuid = getters.getUuid;

                // If no uuid reject as user not logged in!
                if (null === uuid) {
                    console.error('Cannot fetch user from API: no active user UUID set in Vuex!');
                    return reject();
                }

                if (false === state.user.fetched || true === forceReload) {
                    state.user.fetched = true;

                    http.get(`/api/users/${uuid}`)
                        .then(response => {
                            commit('updateUserData', response.data.data);
                            return resolve(response);
                        })
                        .catch(error => {
                            commit('updateUserHasFetched', false);
                            return reject(error);
                        });
                } else {
                    resolve(state.user.data);
                }
            });
        },
    },
    getters   : {
        getUuid             : state => state.user.uuid,
        appearsLoggedIn     : state => null !== state.user.uuid,
        getName             : state => state.user.data.name,
        getEmail            : state => state.user.data.email,
        getOptIn            : state => state.user.data.opt_in,
        getUser             : state => state.user.fetched && state.user.data ? state.user.data : null,
        getUserFetchedStatus: state => state.user.fetched,
        getLearningIso      : state => state.user.data.learning_language,
        getInterfaceIso     : state => state.user.data.interface_language,
        getActiveCourseUuid : state => state.user.data.active_course_uuid,
        getActiveLessonUuid : state => state.user.data.active_lesson_uuid,
        getUserProfileLoaded: state => !!(state.user.fetched && state.user.data.name),
        getUserIsAdmin      : state => state.user.data.is_admin || false,
        getOnlyFetchFromApi : state => state.user.local.onlyFetchFromApi,
        getAccessToken      : state => state.user.local.access_token || null,
    },
};
