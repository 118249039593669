import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import RouterConfig from '@/config/Router';
import StaticPages from '@/routes/StaticPages';
import multiguard from 'vue-router-multiguard';
import {INTERFACE_LANGUAGES} from '@/resources/Countries';
import BasicFooter from '@/components/structure/BasicFooter';
import RedirectGuests from '@/middleware/RedirectGuestsMiddleware';
import CheckIfPasswordRequired from '@/middleware/CheckIfPasswordRequiredMiddleware';
import RedirectAuthenticated from '@/middleware/RedirectAuthenticatedUsersMiddleware';
import RedirectInvalidCourse from '@/middleware/RedirectAuthenticatedUserWithInvalidActiveCourse';

Vue.use(Router);

const isPreview           = (process.env.VUE_APP_IS_PREVIEW || '').toLowerCase() === 'true';
const isProduction        = (process.env.VUE_APP_ENV || '').toLowerCase() === 'production';
const isPasswordProtected = process.env.VUE_APP_PASSCODE_ENABLED || false;

const localisedHomeRoutes = INTERFACE_LANGUAGES.map(country => {
    return {
        path       : `/${country.iso}`,
        name       : `${country.iso}.home`,
        beforeEnter: RedirectAuthenticated,
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "home-localised" */ '@/views/home/TranslatedHome'),
            footer : BasicFooter,
        },
        meta       : {
            iso: country.iso.toUpperCase(),
        },
    };
});

const developmentRoutes = !isProduction
    ? {
        path      : `/styles`,
        name      : `styles.index`,
        components: {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "home-localised" */ '@/views/other/StyleGuide'),
        },
    } : {};

const securityWallRoute = isPasswordProtected
    ? {
        path      : `/security-wall`,
        name      : `security-wall.index`,
        components: {
            default: () => import(/* webpackChunkName: "security-wall" */ '@/views/other/SecurityWall'),
        },
    } : {};

const routes = [
    {
        path       : '/',
        name       : 'home',
        beforeEnter: RedirectAuthenticated,
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: Home,
            footer : BasicFooter,
        },
    },
    {
        path       : '/auth',
        name       : 'auth',
        beforeEnter: RedirectAuthenticated,
        redirect   : {name: 'auth.login'},
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "webapp" */ '@/views/Auth'),
        },
        children   : [
            {
                path    : 'registration',
                name    : 'auth.register',
                redirect: {name: 'auth.register.step-1'},
            },
            {
                path      : 'registration/step-1',
                name      : 'auth.register.step-1',
                components: {
                    header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                    default: () => import(/* webpackChunkName: "auth.register" */ '@/views/auth/registration/StepOne'),
                    footer : BasicFooter,
                },
            },
            {
                path      : 'registration/step-2',
                name      : 'auth.register.step-2',
                components: {
                    header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                    default: () => import(/* webpackChunkName: "auth.register" */ '@/views/auth/registration/StepTwo'),
                    footer : BasicFooter,
                },
            },
            {
                path      : 'registration/step-3',
                name      : 'auth.register.step-3',
                components: {
                    header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                    default: () => import(/* webpackChunkName: "auth.register" */ '@/views/auth/registration/StepThree'),
                    footer : BasicFooter,
                },
            },
            {
                path      : 'login',
                name      : 'auth.login',
                components: {
                    header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
                    default: () => import(/* webpackChunkName: "auth.login" */ '@/views/auth/Login'),
                    footer : BasicFooter,
                },
            },
        ],
    },
    {
        path       : '/app',
        name       : 'app',
        beforeEnter: multiguard([RedirectGuests]),
        redirect   : {name: 'app.dashboard'},
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "webapp" */ '@/views/WebApp'),
        },
        children   : [
            {
                path       : 'dashboard',
                name       : 'app.dashboard',
                beforeEnter: RedirectInvalidCourse,
                component  : () => import(/* webpackChunkName: "app.dashboard" */ '@/views/webApp/Dashboard'),
            },
            {
                path     : 'courses',
                name     : 'app.courses',
                component: () => import(/* webpackChunkName: "app.courses" */ '@/views/webApp/Courses'),
            },
            {
                path       : 'progress',
                name       : 'app.progress',
                beforeEnter: RedirectInvalidCourse,
                component  : () => import(/* webpackChunkName: "app.progress" */ '@/views/webApp/Progress'),
            },
            {
                path     : 'user/account/edit',
                name     : 'app.user.account.edit',
                component: () => import(/* webpackChunkName: "app.user.account.edit" */ '@/views/webApp/user/account/Edit'),
            },
        ],
    },
    {
        path       : '/app/learn',
        name       : 'app.learn',
        beforeEnter: RedirectGuests,
        components : {
            header : null,
            default: () => import(/* webpackChunkName: "app.learn" */ '@/views/webApp/Learn'),
        },
        redirect   : {name: 'app.dashboard'},
        children   : [
            {
                path     : 'unit',
                name     : 'app.learn.unit',
                component: () => import(/* webpackChunkName: "app.learn.unit" */ '@/views/webApp/learn/Unit'),
            },
            {
                path     : 'unit/success',
                name     : 'app.learn.unit.success',
                component: () => import(/* webpackChunkName: "app.learn.unit" */ '@/views/webApp/learn/UnitSummary'),
            },
            {
                path     : 'unit/fail',
                name     : 'app.learn.unit.fail',
                component: () => import(/* webpackChunkName: "app.learn.unit" */ '@/views/webApp/learn/UnitSummary'),
            },
        ],
    },
    {
        path       : '/terms',
        name       : 'terms.index',
        beforeEnter: RedirectAuthenticated,
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "header" */ '@/views/TermsAndConditions'),
            footer : BasicFooter,
        },
    },
    {
        path       : '/privacy',
        name       : 'privacy.index',
        beforeEnter: RedirectAuthenticated,
        components : {
            header : () => import(/* webpackChunkName: "header" */ '@/components/structure/Header'),
            default: () => import(/* webpackChunkName: "header" */ '@/views/Privacy'),
            footer : BasicFooter,
        },
    },
];

const errorRoutes = [
    {
        path     : '*',
        component: () => import(/* webpackChunkName: "app.errors" */ '@/views/errors/404.vue'),
    },
];

const previewAppRoutes = [
    {
        path      : '/',
        name      : 'home',
        components: {
            default: () => import('@/views/home/ComingSoon'),
        },
    },
];

const finalRouteCollection = isPreview
    ? previewAppRoutes
        .concat(errorRoutes)
    : routes
        .concat(securityWallRoute)
        .concat(localisedHomeRoutes)
        .concat(errorRoutes)
        .concat(developmentRoutes)
        .concat(StaticPages);

const router = new Router({
    mode  : 'history',
    base  : process.env.BASE_URL,
    routes: finalRouteCollection
        .filter(value => Object.keys(value).length !== 0),
});

router.beforeEach(
    multiguard([RouterConfig, CheckIfPasswordRequired]),
);

export default router;
