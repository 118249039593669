import FetchUnit from '@/services/Unit/FetchUnit';

export default class UnitService {
    /**
     * Handle fetching the unit and its exercises.
     *
     * @param {string} unitUuid
     *
     * @returns {Promise<any>}
     */
    static fetchUnit = unitUuid => (new FetchUnit()).handle(unitUuid);
}
