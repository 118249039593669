export const STATE_CLOSED = 'CLOSED';
export const STATE_HALF   = 'HALF';
export const STATE_OPEN   = 'OPEN';

export class CircuitBreaker {
    /**
     * The function to fire.
     *
     * @param {Function}
     */
    func;

    /**
     * CircuitBreaker constructor.
     *
     * @param {Function} func
     * @param {*} options
     * @param {string} name
     */
    constructor(func, options = {}, name = 'Generic circuit') {
        this.func             = func;
        this.state            = STATE_CLOSED;
        this.failureThreshold = options.failureThreshold || 3;
        this.failureCount     = options.failureCount || 0;
        this.successThreshold = options.successThreshold || 2;
        this.successCount     = options.successCount || 0;
        this.timeout          = options.timeout || 6000;
        this.nextAttempt      = Date.now();
        this.name             = name;
    }

    /**
     * Attempt to fire the request.
     *
     * @return {Promise}
     */
    async fire() {
        return new Promise((resolve, reject) => {
            if (this.state === STATE_OPEN) {
                if (this.nextAttempt <= Date.now()) {
                    this.state = STATE_HALF;
                } else {
                    return reject(`${this.name} is currently OPEN`);
                }
            }

            this.func.apply(null, arguments)
                .then(result => resolve(this.success(result)))
                .catch(error => reject(this.fail(error)));
        });
    }

    /**
     * Handle a success request.
     *
     * @param {*} response
     *
     * @return {*}
     */
    success(response) {
        // Logic to handle successful requests
        if (this.state === STATE_HALF) {
            this.successCount++;

            if (this.successCount > this.successThreshold) {
                this.successCount = 0;
                this.state        = STATE_CLOSED;
            }
        }

        this.failureCount = 0;

        return response;
    }

    /**
     * Handle an unsuccessful response.
     *
     * @param {*} err
     *
     * @return {*}
     */
    fail(err) {
        this.failureCount++;

        if (this.failureCount >= this.failureThreshold) {
            this.state       = STATE_OPEN;
            this.nextAttempt = Date.now() + this.timeout;
        }

        return err;
    }
}
