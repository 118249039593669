import Entity from '@/entities/Entity';
import courseService from '@/services/Course/CourseService';

const getDefaultState = () => {
    return {
        course: {
            fetched: false,
            uuid   : null,
            data   : null,
        },
    };
};

export default {
    namespaced: true,
    state     : getDefaultState(),
    mutations : {
        /**
         * Reset current state.
         *
         * @param {*} state
         *
         * @returns {any}
         */
        resetState: state => Object.assign(state, getDefaultState()),

        /**
         * Update the course data.
         *
         * @param {*}      state
         * @param {Array}  payload
         *
         * @returns {void}
         */
        setCourse(state, payload) {
            state.course.data = payload;
        },

        /**
         * Update the UUID.
         *
         * @param {*} state
         * @param {String} uuid
         */
        updateUuid: (state, uuid) => {
            state.course.uuid = uuid;
        },
    },
    actions   : {
        /**
         * Fetch and update the courses available.
         *
         * @param {*} commit
         * @param {*} state
         * @param {*} rootGetters
         * @param {Boolean} forceReload
         *
         * @returns {Promise<any>}
         */
        fetchCourse({commit, state, rootGetters}, forceReload) {
            return new Promise((resolve, reject) => {
                const activeCourseUuid = rootGetters['Account/getActiveCourseUuid'];

                activeCourseUuid === null ? reject('No active course selected on account!') : null;

                if (!state.course.fetched || forceReload === true || state.course.uuid !== activeCourseUuid) {
                    state.course.fetched = true;

                    courseService.fetchCourse(activeCourseUuid)
                        .then(data => {
                            commit('setCourse', data);
                            commit('updateUuid', activeCourseUuid);
                            resolve();
                        })
                        .catch(error => {
                            state.course.fetched = false;
                            reject(error);
                        });
                }
            });
        },
    },
    getters   : {
        /**
         * Get the course data.
         *
         * @param {*} state
         *
         * @returns {Array|null}
         */
        getCourse: state => {
            if (state.course.data) {
                return {
                    ...state.course.data,
                    entity: new Entity(state.course.data.entity),
                };
            }

            return null;
        },

        /**
         * Determine whether the course has fetched.
         *
         * @param {*} state
         *
         * @return {boolean}
         */
        courseHasFetched: state => state.course.fetched,

        /**
         * Get the available lessons for the loaded course.
         *
         * @param {*} state
         *
         * @returns {Array|null}
         */
        getCourseLessons: state => {
            if (state.course.data) {
                return state.course.data.lessons
                    .map(lesson => {
                        let lessonData = {
                            ...lesson,
                            entity: new Entity(lesson.entity || {}), // Swap out entity to Entity model
                        };

                        if ((lessonData.units || []).length > 0) {
                            lessonData.units = lessonData.units
                                .map(unit => {
                                    return {
                                        ...unit,
                                        entity: new Entity(unit.entity || {}), // Swap out entity to Entity model
                                    };
                                });
                        }

                        return lessonData;
                    });
            } else {
                return null;
            }
        },

        /**
         * Get the user's active course name.
         *
         * @param {*} state
         *
         * @return {String|null}
         */
        getCourseName: state => state.course.data ? state.course.data.name : null,

        /**
         * Get the user's active course name.
         *
         * @param {*} state
         *
         * @return {String|null}
         */
        getCourseLanguageIso: state => state.course.data ? state.course.data.language_iso : null,

        /**
         * Get the user's active course with the units sorted.
         *
         * @param {*} state
         * @param {*} getters
         * @param {*} rootState
         * @param {*} rootGetters
         *
         * @return {*}
         */
        getActiveCourseLesson: (state, getters, rootState, rootGetters) => {
            const lessonUuid = rootGetters['Account/getActiveLessonUuid'];
            const lessons    = getters.getCourseLessons || [];
            const lesson     = null === lessonUuid
                ? lessons[0]
                : lessons.find(lesson => lesson.id === lessonUuid);

            if (lesson && lesson.units) {
                // Add the previous unit uuid
                lesson.units.map((unit, index) => {
                    const previousUnit  = index > 0 ? lesson.units[index - 1] : null;
                    const nextUnitIndex = index + 1;
                    const nextUnit      = lesson.units[nextUnitIndex] || null;

                    unit.previous_unit_uuid = previousUnit ? previousUnit.id : null;
                    unit.next_unit_uuid     = nextUnit ? nextUnit.id : null;
                });
            }


            return lesson;
        },

        /**
         * Calculate the user's progress in the current active course.
         *
         * @param {*} state
         * @param {*} getters
         * @param {*} rootState
         * @param {*} rootGetters
         *
         * @return {number}
         */
        getCourseCompletionPercentage: (state, getters, rootState, rootGetters) => {
            const userHasPassedUnit = rootGetters['Progress/userHasPassedUnit'];
            const lessons           = getters.getCourseLessons;
            let totalUnits          = 0;
            let unitsCompleted      = 0;

            lessons.forEach(lesson => {
                let units = lesson.units;

                units.forEach(unit => {
                    totalUnits++;
                    if (userHasPassedUnit(unit.id)) unitsCompleted++;
                });
            });

            return Math.min((unitsCompleted / totalUnits) * 100, 100);
        },

        /**
         * Return all the lessons the user has fully completed.
         *
         * @param {*} state
         * @param {*} getters
         * @param {*} rootState
         * @param {*} rootGetters
         *
         * @return {Array}
         */
        getAllCompletedLessons: (state, getters, rootState, rootGetters) => {
            const userHasPassedUnit = rootGetters['Progress/userHasPassedUnit'];
            const lessons           = getters.getCourseLessons;

            return lessons.filter(lesson => {
                let units              = lesson.units;
                let hasIncompleteUnits = false;

                units.forEach(unit => {
                    if (false === userHasPassedUnit(unit.id)) hasIncompleteUnits = true;
                });

                // If we get here all the units have passed so the user's finished the lesson
                return !hasIncompleteUnits;
            });
        },
    },
};
